import serviceBgPicDrinks from '../assets/img/service/Service_1.jpg';
import serviceBgPicFoods from '../assets/img/service/Service_2.jpg';
import serviceBgPicMedicine from '../assets/img/service/Service_3.jpg';
import serviceBgPicOther from '../assets/img/service/Service_4.jpg';

import beveragePic1 from '../assets/img/service/02_beverage/02_1-1.jpg';
import beveragePic2 from '../assets/img/service/02_beverage/02_2-1.jpg';
import beveragePic3 from '../assets/img/service/02_beverage/02_3-1.jpg';
import beveragePic4 from '../assets/img/service/02_beverage/02_4-1.jpg';

import foodsPic1 from '../assets/img/service/03_foods/02_1-1.jpg';
import foodsPic2 from '../assets/img/service/03_foods/02_2-1.jpg';
import foodsPic3 from '../assets/img/service/03_foods/02_3-1.jpg';
import foodsPic4 from '../assets/img/service/03_foods/02_4-1.jpg';
import foodsPic5 from '../assets/img/service/03_foods/02_5-1.jpg';
import foodsPic6 from '../assets/img/service/03_foods/02_6-1.jpg';

import medicinePic1 from '../assets/img/service/01_medicine/02_1-1.jpg';
import medicinePic2 from '../assets/img/service/01_medicine/02_2-1.jpg';
import medicinePic3 from '../assets/img/service/01_medicine/02_3-1.jpg';

import otherPic1 from '../assets/img/service/04_other/02_1-1.jpg';
import otherPic2 from '../assets/img/service/04_other/02_2-1.jpg';
import otherPic3 from '../assets/img/service/04_other/02_3-1.jpg';
import otherPic4 from '../assets/img/service/04_other/02_4-1.jpg';

export default [
  {
    type: 'beverage',
    typeName: '飲料',
    mainBg: serviceBgPicDrinks,
    intro: `
      本公司負責麒麟集團全日本的工廠投資以及海外的設備投資。<br>
      集團擁有啤酒工廠、飲料工廠、威士忌酒廠、葡萄酒廠、乳製品工廠，多年來累積了許多技術。<br>
      因此，從生產現場到衛生管理、微生物對策、洗淨技術、污水處理、副產物處理、耐震對應等等實戰經驗，這些經驗成為穩固的後盾，並融合麒麟技術，提供安全、安心的設計。
    `,
    info: [
      {
        pic: beveragePic1,
        title: '啤酒',
        subtitle: 'BEER',
        infoText: `
          麒麟集團以啤酒工廠為基礎，過去是當地的製酒商，現在則投資精釀啤酒工廠，利用我們豐富的專業知識進行設備投資，提供高品質和操作性便利的設備。我們以亞洲為中心，擁有很多國外的工程實績。
        `,
        infoList: [
          '整廠設計、施工',
          '釀造、發酵設備的自動化、客製化',
          '餐廳結合釀造廠的提案',
        ],
      },
      {
        pic: beveragePic2,
        title: '酒精飲料',
        subtitle: 'ALCOHOLIC BEVERAGE',
        infoText: '我們擁有廣泛的業務範圍，從葡萄酒、日本酒、威士忌、燒灼的釀造和蒸餾，到生質酒精工廠及海外威士忌、高粱酒的蒸餾工廠。 此外，還有一種稱為RTD(Ready to drink)的商品，例如Chu-hai(酎ハイ)、High-ball(ハイボール)、蘋果酒、雞尾酒等等，將酒類生產技術及飲料調合技術相互結合，並提案給工廠，依據產品市場需求，少量、多樣化生產。',
      },
      {
        pic: beveragePic3,
        title: '無酒精飲料',
        subtitle: 'SOFT DRINK',
        infoText: `
          本公司擁有日本國內外的麒麟飲料及集團企業的飲料工廠實績，以及其他廠商委託的工廠實績。<br>
          提供的服務範圍中，包含瓶、罐、PET、BIB等等的包材充填，液體類型包含礦泉水、無酒精飲料、碳酸飲料、運動飲料、能量飲料，充填方式包含無菌充填、熱充填及冷充填，並提供低速~高速的生產線規劃。
        `,
      },
      {
        pic: beveragePic4,
        title: '乳製品、豆漿製品',
        subtitle: 'MILK AND SOYMILK',
        infoText: `
          麒麟集團擁有生乳、乳飲料、乳酸菌飲料、優格的生產工廠，以及起司、奶油、冰淇淋、奶粉等等多樣的乳製品生產工廠的實績。 此外，對於豆漿工廠及成為新議題的副產物處理設備，皆可執行提案。
        `,
      },
    ],
  },
  {
    type: 'foods',
    typeName: '食品',
    mainBg: serviceBgPicFoods,
    intro: `
      本公司負責麒麟集團全日本的工廠投資以及海外的設備投資。<br>
      集團內有啤酒工廠、飲料工廠、乳製品工廠，長年累積食品工廠中的共通技術及特有技術，從生產現場到衛生管理、微生物對策、洗淨技術、污水處理、副產物處理等等，這些經驗成為穩固的後盾，並融合麒麟食品工廠30年的工程技術，提供高效益、安全、安心設計。<br>
      本公司從使用者的觀點，讓食品企業的客戶們可以建構一個高效率、安心、安全的工廠。
    `,
    info: [
      {
        pic: foodsPic1,
        title: '調味料',
        subtitle: 'SEASONING',
        infoText: '我們擁有醬料、調味品、醋、油等等液體生產工廠的實績，成為多樣的食品製造工廠的成果之一，我們提案範圍包含CIP、配管技術、自動化，以及粉體技術、發酵技術、調合等等提案，提供各種充填方式及低速~高速的生產線規劃。',
      },
      {
        pic: foodsPic2,
        title: '動、植物油脂',
        subtitle: 'ANIMAL FAT/VEGETABLE OIL',
        infoText: '除了參與麒麟集團的生產工廠外，我們透過多樣的食品工廠實績，從流程、液體技術、萃取、壓榨、精製、調合等技術的提案，到規劃各種充填方式及低速~高速生產線，此外，我們會針對食品工廠提出建議，例如除臭等等的環境措施，以及最佳的污水處理方式。',
      },
      {
        pic: foodsPic3,
        title: '點心、麵包',
        subtitle: 'CONFECTIONERY AND BAKERY',
        infoText: '本公司從使用者的觀點，讓食品企業的客戶們可以建構一個高效率、安心、安全的工廠。<br>除了參與麒麟集團的生產工廠外，我們擁有多樣的烘焙食品工廠實績，利用流程、粉體技術並給予提案，提案範圍包含原料混合、成型、烘烤、計量、包裝等等各工程的技術，以及規畫多樣的自動化及產線化工廠。此外，我們會針對食品工廠提出建議，例如除臭等等的環境措施及最佳的污水處理方式。',
      },
      {
        pic: foodsPic4,
        title: '配菜、便當',
        subtitle: 'SIDE DISH AND BOX LUNCH',
        infoText: '本公司從使用者的觀點，讓食品企業的客戶們可以建構一個高效率、安心、安全的工廠。<br>除了參與麒麟集團的生產工廠外，我們也參與過各式各樣的加工食品工廠實績，因此，我們會提供各工程的技術提案，包含人事精簡的效率化規劃以及產線化規劃，並提供符合客戶需求的產線配置計畫，此外，我們也會針對客戶需求提出建議，例如工廠的結露、除臭等環境措施、粉塵以及最佳的污水處理方式。',
      },
      {
        pic: foodsPic5,
        title: '肉品、水產',
        subtitle: 'MEAT FISHERY',
        infoText: `
          本公司從使用者的觀點，讓食品企業的客戶們可以建構一個高效率、安心、安全的工廠。<br>
          農畜水產業界面臨很多的食品衛生課題，例如大腸桿菌O-157等等，我們以製造者的立場導入HACCP，除此之外，歐美FDA的衛生課題也是對日本食品界影響甚大。<br>
          除了參與麒麟集團的生產工廠外，我們也有參與過各式各樣的農畜水產加工食品工廠實績，利用流程、配管技術、衛生管理技術，執行冷凍解凍·自動化的最佳產線規劃，以及提供適合客戶需求的產線配置計畫，設計範圍包含從生產設備至整廠建築的提案，此外，我們會針對客戶需求提出建議，例如工廠的結露、除臭等環境措施及最佳的污水處理方式。
        `,
      },
      {
        pic: foodsPic6,
        title: '麵',
        subtitle: 'NOODLES',
        infoText: '本公司從使用者的觀點，讓食品企業的客戶們可以建構一個高效率、安心、安全的工廠。<br>除了麒麟集團旗下的生產工廠外，我們也有參與過各式各樣的麵類食品工廠實績，設計範圍包含從生產設備至整廠建築的提案。充分將製程、調配、粉體混和等領域技術運用於產線中，從每項工程的技術提案裡，考慮自動化、最有效率的人事精簡，並執行最適合客戶需求的產線配置計畫，此外，我們也針對食品工廠提出建議，例如工廠的結露、除臭等環境措施及最佳的污水處理方式。',
      },
    ],
  },
  {
    type: 'medicine',
    mainBg: serviceBgPicMedicine,
    typeName: '醫藥',
    intro: `
      本公司不僅有建設醫藥品工廠，並於集團的醫藥品工廠設立「GMP設施管理部門」，參與營運、定期驗證、定期修繕的事物。<br>
      於新工廠建設有長年的經驗，實際參與視察及問題解決、工廠建設可行性研究，這些皆呈現在基本計畫裡。 啟動「麒麟技術工程循環」，構築最新的醫藥品工廠。
    `,
    info: [
      {
        pic: medicinePic1,
        title: '生物藥',
        subtitle: 'BIOMEDICINE',
        infoText: `
          由於生物藥分子量大且構造複雜，如果在製造過程中出現細微的變化，也是有可能影響最終的產物，本公司以KIRIN的發酵技術為基礎，擁有培養廠的豐富建設實績，對於「微生物」「動物細胞」所製造的生物藥，我們建設的工廠是可以維持它的安全性及有效性。
        `,
      },
      {
        pic: medicinePic2,
        title: '無菌製劑',
        subtitle: 'STERILE PRODUCTS',
        infoText: `
          本公司建構無菌製劑工廠，有多樣的生物醫藥品，包含Vial瓶、安瓿、預填充式注射器等等不同劑型的注射劑(包含凍結乾燥劑)。
        `,
      },
      {
        pic: medicinePic3,
        title: '再生醫療',
        subtitle: 'REGENERATIVE MEDICINE',
        infoList: [
          '設置附著依存性細胞的高密度培養裝置',
          '積極採用機器人，建構降低污染風險的細胞培養設備',
          '建構最佳分區的細胞培養中心',
        ],
      },
    ],
  },
  {
    type: 'other',
    typeName: '其他',
    mainBg: serviceBgPicOther,
    intro: '',
    info: [
      {
        pic: otherPic1,
        title: '健康食品',
        subtitle: 'HEALTH FOOD',
        infoText: `
          隨著健康意識抬頭，可預想未來營養機能食品(DHA、EPA、維他命、葉酸、鋅、鐵)的需求會增加，未來會使用「個人檢測試劑」檢查患者的甲狀腺、壓力、睡眠、重金屬、生育能力等等，提出適合每個人的「個人化營養品」， 並且這已備受關注，本公司建設的工廠，提供獲得驗證的高品質製品。
        `,
      },
      {
        pic: otherPic2,
        title: '香料',
        subtitle: 'FLAVOR',
        infoText: `
          本公司會建設「食品添加物」工廠，食品添加物使用於食品製造過程中，例如防腐劑、甜味劑、著色劑、香料等等。
        `,
        infoList: [
          '利用水蒸氣蒸餾裝置將茶、咖啡、穀物進行萃取。',
          '利用珪藻土過濾、壓濾機、碟式離心機、薄膜蒸發器、臥螺沉降離心機進行澄清過濾。',
          '根據顧客的需求，最終包裝形式為BIB包裝(bag in box)等。',
        ],
      },
      {
        pic: otherPic3,
        title: '盥洗用品(含化妝品)',
        subtitle: 'TOILETRIES',
        infoText: `
          由於亞洲、太平洋市場的擴大，乳液、防曬等等的化妝品需求與日俱增。
        `,
        infoList: [
          '輸出至美國的化妝品，和醫藥品一樣是cGMP審查對象，我們提供支援並於醫藥品工廠培育驗證技術(IQ/OQ)',
          '考量到製品的出入動線',
          '導入最新檢查裝置(異物、容器破損等等)',
        ],
      },
      {
        pic: otherPic4,
        title: '環境',
        subtitle: 'ENVIRONMENT',
        infoText: `
          對於大量排放有機廢棄物的工廠，本公司積極導入「再生能源」，並且提倡節能工廠建設。<br>
          目前為止，我們已經參與了很多工廠的建設，例如食品廢棄物以及雜質性生物質中回收再利用的「生質酒精」及「甲烷氣體」等等。        
        `,
      },
    ],
  },
];
