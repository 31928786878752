<template>
  <div
    class="sibling_navbox main_padding"
    v-if="siblingData.length">
      <div class="inner">
        <router-link
          :to="item.route || ''"
          class="item"
          v-for="(item, i) in siblingData"
          :key="`siblingNavbox_${i}`">
            <div class="pic">
              <figure
                :style="{
                  backgroundImage: `url(${item.pic})`
                }">
              </figure>
            </div>
            <div class="text">
              <div class="left">
                <h6 class="txt-up txt-bold">{{item.title}}</h6>
                <p class="txt-theme">{{item.subTitle}}</p>
              </div>
              <div class="arrow-circle"></div>
            </div>
        </router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'SiblingNavbox',
  props: {
    siblingData: {
      type: Array,
      default: () => [
        {
          pic: '',
          title: 'Default Title',
          subTitle: '預設標題',
          route: '',
        },
      ],
    },
  },
  // props: ['pic', 'title', 'subTitle', 'route'],
  data() {
    return {
    };
  },
};
</script>
