import requestApi from '@/lib/http/index';

async function getSeoByPath(path) {
  const { entries: seoData } = await requestApi(
    'seo.getMetaData',
  );
  return seoData?.find((data) => data.path.includes(path))
    || seoData?.find((data) => data.path.includes('/'));
}

export default {
  async created() {
    const currentPath = this.$route?.path;

    let pathOrigin = '/';
    if (currentPath !== '/') {
      if (currentPath.indexOf('news') !== -1) {
        pathOrigin = '/news';
      } else {
        pathOrigin = `/${currentPath.split('/')[1]}`;
      }
    }

    const { title } = await getSeoByPath(pathOrigin);

    if (title) {
      document.title = title;
    } else {
      document.title = 'KIRIN Engineering';
    }
  },
};
