<template>
  <div
    class="service_info_item main_padding"
    :class="[
      { left_text: direction == 'left' || !direction },
      { right_text: direction == 'right' }
    ]">
      <div class="text">
        <h5 class="title txt-bold">{{title}}</h5>
        <h6 class="txt-bold">{{subtitle}}</h6>
        <p
          class="info_text"
          v-if="infoText"
          v-html="infoText">
        </p>
        <ul
          v-if="infoList"
          class="info_list">
            <li
              v-for="(item, i) in infoList"
              :key="`infoList${title}${i}`">
              <p>
                <span class="txt-theme">・</span>
                {{item}}
              </p>
            </li>
        </ul>
      </div>
      <div class="pic">
        <figure
          :style="{
            backgroundImage: `url(${pic})`
          }">
        </figure>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceInfoItem',
  props: [
    'direction',
    'title',
    'subtitle',
    'infoText',
    'infoList',
    'pic',
  ],
  data() {
    return {
    };
  },
  components: {
  },
};
</script>

<style
  scoped
  src="@/assets/scss/service.scss"
  lang="scss">
</style>
