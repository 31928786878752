<template>
  <section id="service">
    <div
      id="kv"
      :style="{
        backgroundImage: `url(${nowPageData.mainBg})`
      }">
        <div class="title w1200 txt-white">
          <h2 class="txt-bold txt-up">
            {{nowPageData.type}}
          </h2>
        </div>
        <div class="text w1200 txt-white">
          <div class="bread">
            <small>首頁 - {{nowPageData.typeName}}</small>
          </div>
          <div
            class="info"
            v-html="nowPageData.intro">
          </div>
        </div>
    </div>

    <div
      id="service_info"
      class="main_padding"
      v-if="nowPageData.info">
        <div class="inner w1400">
          <div
            class="tabs"
            :class="[
              { col3 : nowPageData.info && nowPageData.info.length > 4 }
            ]">
              <div
                class="tab"
                v-for="(item, i) in nowPageData.info"
                :key="`ServiceInfoTab${nowPageData.type}${i}`"
                @click="scrollTo($refs.ServiceInfoItems[i].$el);">
                  <h6>{{item.title}}</h6>
              </div>
          </div>

          <div class="itembox">
            <ServiceInfoItem
              v-for="(item, i) in nowPageData.info"
              :key="`ServiceInfoItem${nowPageData.type}${i}`"
              :ref="'ServiceInfoItems'"
              :pic="item.pic"
              :title="item.title"
              :subtitle="item.subtitle"
              :infoText="item.infoText"
              :infoList="item.infoList"
              :direction="(() => {
                return (i%2==0) ? 'left' : 'right';
              })()"/>
          </div>
        </div>
    </div>

    <SiblingNavbox
      :siblingData="siblingData"/>
  </section>
</template>

<script>
import servicePageData from '@/utils/servicePageData';
import ServiceInfoItem from '@/components/page/service/ServiceInfoItem.vue';
import SiblingNavbox from '@/components/public/SiblingNavbox.vue';
import titleMixin from '@/mixins/titleMixin';

export default {
  name: 'Service',
  mixins: [titleMixin],
  props: ['type'],
  data() {
    return {
      servicePageData,
    };
  },
  components: {
    ServiceInfoItem,
    SiblingNavbox,
  },
  computed: {
    nowPageData() {
      return servicePageData.find(
        (item) => item.type === this.type,
      );
    },
    siblingData() {
      return servicePageData
        .filter((item) => item.type !== this.type)
        .map((item) => ({
          pic: item.mainBg,
          title: item.type,
          subTitle: item.typeName,
          route: {
            name: 'Service',
            params: { type: item.type },
          },
        }));
    },
  },
  watch: {
    $route() {
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      });
    },
  },
  methods: {
    scrollTo(elem) {
      console.log(elem, window);
      window.scrollTo({
        top: elem.offsetTop - 50,
        behavior: 'smooth',
      });
    },
  },
  created() {
    if (!this.nowPageData) {
      this.$router.push(
        { name: 'Home' },
      );
    }
  },
};
</script>

<style
  scoped
  src="@/assets/scss/service.scss"
  lang="scss">
</style>
